<template>
    <div class="flex flex-row m-auto min-h-screen px-52">
        <div class="basis-1/2 flex items-center justify-center">
            <form action="">
                <div class="mb-5">
                    <v-input
                        id="email"
                        class="w-full"
                        type="email"
                        labelText="Email"
                        v-model="form.email"
                    />
                </div>

                <div class="mb-8">
                    <v-input
                        id="password"
                        class="w-full"
                        type="password"
                        labelText="Password"
                        :error-message="errors && errors.message ? errors.message[0] : ''"
                        v-model="form.password"
                    />
                </div>

                <v-button
                    class="py-2 px-4 bg-blue-300 rounded-lg mr-4"
                    :disabled="loading"
                    @click.prevent="login"
                >
                    Log in
                </v-button>
            </form>
        </div>

        <div class="basis-1/2 px-20 flex items-center">
            <img src="../../assets/images/auth.jpg"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "",
    data() {
        return {
            value: "",
            form: {
                email: "",
                password: "",
            },

            loading: false,
            errors: {},
        };
    },
    methods: {
        login() {
            this.loading = true;
            let formData = new FormData();

            for (let key in this.form) {
                formData.append(key, this.form[key]);
            }
            this.$axios
                .get(`${process.env.VUE_APP_URL}/sanctum/csrf-cookie`)
                .then((res) => {
                    this.$axios
                        .post("/login", formData)
                        .then((res) => {
                            if (res.data.token) {
                                localStorage.setItem("access-token", res.data.token);
                                this.$router.push({name: "dashboard-index"});
                                this.$store.dispatch("loadMe");
                            }
                            this.loading = false;
                        })
                        .catch((e) => {
                            this.errors = e.data;
                            this.loading = false;
                        });
                });
        },
    },
};
</script>

<style scoped lang="scss"></style>
